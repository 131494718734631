<template>
  <!--card-form-->
  <div id="transaction-config-card" class="card cardlabel sticky-top align-top">
    <div class="expander" @click="isExpand = !isExpand">
      <h2>Configuration</h2>
      <font-awesome-icon
        icon="fa-solid fa-chevron-down"
        :class="{ 'icon-rotate': isExpand === true }"
      />
    </div>
    <div
      v-if="isCopyTrading()"
      class="card-body"
      :class="{ 'is-expand': isExpand === true }"
    >
      <div class="d-flex flex-wrap">
        <div class="col-lg-6 pe-lg-3">
          <div class="d-flex">
            <div class="" style="margin-right: 8px; margin-top: 3px">
              <h2 class="m-0">Buy</h2>
            </div>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-6 pe-lg-3 col-sm-12 col-12">
              <div class="">
                <label class="form-label"
                  >{{ getNetwork().currency }} Amount<img
                    class="ms-lg-2 pointer"
                    src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6208171)"
                /></label>
                <input
                  v-model="config.buyAmount"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
            <div class="col-lg-6 ps-lg-3 col-sm-12 col-12 mobile-mt">
              <div class="d-flex align-items-center dm">
                <input
                  v-model="config.isBuyInstant"
                  id="check"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="..."
                />
                <label class="me-2 m-0 ps-3" for="check">Buy Instant</label>
                <img
                  class="ms-lg-2 pointer"
                  src="img/info-card.svg"
                  onclick="Intercom('showArticle', 6208167)"
                />
              </div>
              <div class="d-flex align-items-center dm mt-1">
                <input
                  v-model="config.isBuySameAmount"
                  id="check2"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="..."
                />
                <label class="me-2 m-0 ps-3" for="check2">Same Amount</label>
                <img
                  class="ms-lg-2 pointer"
                  src="img/info-card.svg"
                  onclick="Intercom('showArticle', 6208171)"
                />
              </div>
              <div class="d-flex align-items-center dm mt-1">
                <input
                  v-model="config.isBuyOnce"
                  id="check3"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="..."
                />
                <label class="me-2 m-0 ps-3" for="check3">Buy Once</label>
                <img
                  class="ms-lg-2 pointer"
                  src="img/info-card.svg"
                  onclick="Intercom('showArticle', 6208176)"
                />
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-6 pe-3">
              <div class="">
                <label class="form-label"
                  >Normal Gas Multiplier<img
                    class="ms-lg-2 pointer"
                    src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6208167)"
                /></label>
                <input
                  v-model="config.buyGasMultiplier"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="">
                <label class="form-label"
                  >Frontrun Gas Multiplier<img
                    class="ms-lg-2 pointer"
                    src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6208167)"
                /></label>
                <input
                  v-model="config.buyFastGasMultiplier"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mobile-mt">
          <div class="d-flex" style="margin-top: 3px">
            <h2 class="m-0">Sell</h2>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-12 pe-3">
              <div class="d-flex flex-wrap">
                <div class="col-lg-6 pe-3">
                  <div class="">
                    <label class="form-label"
                      >Threshold<img
                        class="ms-lg-2 pointer"
                        src="img/info-card.svg"
                        onclick="Intercom('showArticle', 6208182)"
                    /></label>
                    <input
                      v-model="config.sellThreshold"
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="col-lg-6 ps-lg-3 col-sm-12 col-12 mobile-mt">
                  <div class="d-flex align-items-center dm">
                    <input
                      v-model="config.isSellOnWarn"
                      id="checksellinstant"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      aria-label="..."
                    />
                    <label class="me-2 m-0 ps-3" for="checksellinstant"
                      >Sell Instant</label
                    >
                    <img
                      class="ms-lg-2 pointer"
                      src="img/info-card.svg"
                      onclick="Intercom('showArticle', 6208184)"
                    />
                  </div>
                  <div class="d-flex align-items-center dm mt-3">
                    <input
                      v-model="config.isSellSameAmount"
                      id="checksameamount"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      aria-label="..."
                    />
                    <label class="me-2 m-0 ps-3" for="checksameamount"
                      >Same Amount</label
                    >
                    <img
                      class="ms-lg-2 pointer"
                      src="img/info-card.svg"
                      onclick="Intercom('showArticle', 6208193)"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap mt-2">
                <div class="col-lg-6 pe-3">
                  <div class="">
                    <label class="form-label"
                      >Normal Gas Multiplier<img
                        class="ms-lg-2 pointer"
                        src="img/info-card.svg"
                        onclick="Intercom('showArticle', 6208184)"
                    /></label>
                    <input
                      v-model="config.sellGasMultiplier"
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="">
                    <label class="form-label"
                      >Frontrun Gas Multiplier<img
                        class="ms-lg-2 pointer"
                        src="img/info-card.svg"
                        onclick="Intercom('showArticle', 6208184)"
                    /></label>
                    <input
                      v-model="config.sellFastGasMultiplier"
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card-body" :class="{ 'is-expand': isExpand === true }">
      <div class="d-flex flex-wrap">
        <div class="col-lg-6 pe-lg-3">
          <div class="d-flex">
            <div class="" style="margin-right: 8px; margin-top: 3px">
              <h2 class="m-0">Buy</h2>
            </div>
            <div
              class="col-lg-5 d-flex align-items-center"
              :style="getLevel().canSnipe() ? '' : 'visibility: hidden;'"
            >
              <label
                class="form-label"
                style="margin-right: 6px !important; margin-top: 6px !important"
                >On
                <img
                  id="help-buy-on"
                  class="pointer ms-lg-2"
                  src="img/info-card.svg"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Trigger buy on this function"
                  onclick="Intercom('showArticle', 6020973)"
                />
              </label>
              <input
                style="margin-top: 0px"
                v-model="config.buyOn"
                type="text"
                class="form-control"
                placeholder=""
              />
            </div>
            <a
              v-if="canWatch"
              @click="handleWatch"
              data-mdb-placement="bottom"
              title="Watch"
              style="margin-left: 10px"
            >
              <img
                v-if="isWatch"
                style="width: 30px; height: 30px"
                class="action-icon"
                src="img/unobserve.svg"
              />
              <img
                v-else
                style="width: 30px; height: 30px"
                class="action-icon"
                src="img/observe.svg"
              />
              <span v-if="isWatch">Watching...</span>
            </a>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-6 pe-lg-3 col-sm-12 col-12">
              <div class="">
                <label class="form-label"
                  >{{ getNetwork().currency }} Amount</label
                >
                <input
                  v-model="config.buyAmount"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="">
                <label class="form-label">PK or Unclog count</label>
                <input
                  v-model="config.unclogOrPK"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div v-if="getLevel().canSnipe()" class="d-flex flex-wrap mt-2">
            <div class="col-lg-6 pe-3">
              <div class="">
                <label class="form-label"
                  >Normal Gas Multiplier<img
                    class="ms-lg-2 pointer"
                    src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6021015)"
                /></label>
                <input
                  v-model="config.buyGasMultiplier"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="">
                <label class="form-label"
                  >Gas Limit<img
                    class="ms-lg-2 pointer"
                    src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6208153)"
                /></label>
                <input
                  v-model="config.gasLimitETH"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
            <div class="d-flex flex-wrap mt-2">
              <div class="col-lg-6 pe-3">
                <div class="">
                  <label class="form-label">Initial Pool ETH Amount</label>
                  <input
                    v-model="config.initialPoolETHAmount"
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="">
                  <label class="form-label">Initial Pool Token Percent</label>
                  <input
                    v-model="config.initialPoolTokenPercent"
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mobile-mt">
          <div class="d-flex" style="height: 30px; margin-top: 3px">
            <h2 class="m-0">Sell</h2>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-12 pe-3">
              <div class="d-flex flex-wrap">
                <div class="col-lg-6 pe-lg-3 col-sm-12 col-12">
                  <div class="">
                    <label class="form-label"
                      >Account’s Percent<img
                        class="ms-lg-2 pointer"
                        src="img/info-card.svg"
                        onclick="Intercom('showArticle', 6021011)"
                    /></label>
                    <input
                      v-model="config.sellPercent"
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                  <div class="">
                    <label class="form-label"
                      >Airdrop <small>(amount, count)</small></label
                    >
                    <div class="d-flex flex-wrap">
                      <div class="col-6">
                        <input
                          v-model="config.airdropAmount"
                          type="text"
                          class="form-control col-6"
                          placeholder=""
                        />
                      </div>
                      <div class="col-6">
                        <input
                          v-model="config.airdropCount"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="getLevel().canSnipe()" class="d-flex flex-wrap mt-2">
            <div class="col-lg-12 pe-3">
              <div class="d-flex flex-wrap">
                <div class="col-lg-6 pe-3">
                  <div class="">
                    <label class="form-label"
                      >Normal Gas Multiplier<img
                        class="ms-lg-2 pointer"
                        src="img/info-card.svg"
                        onclick="Intercom('showArticle', 6021020)"
                    /></label>
                    <input
                      v-model="config.sellGasMultiplier"
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="">
                    <label class="form-label"
                      >Frontrun Gas Multiplier<img
                        class="ms-lg-2 pointer"
                        src="img/info-card.svg"
                        onclick="Intercom('showArticle', 6021020)"
                    /></label>
                    <input
                      v-model="config.sellFastGasMultiplier"
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-12 pe-3">
              <div class="d-flex flex-wrap">
                <div class="col-lg-6 pe-3">
                  <div class="">
                    <label class="form-label">Launch Setting</label>
                    <vs-select v-model="config.launchRouter">
                      <vs-option label="Mixed" value="m"> Mixed </vs-option>
                      <vs-option label="Uniswap router" value="u">
                        Uniswap router
                      </vs-option>
                      <vs-option label="Bot router" value="b">
                        Bot router
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="">
                    <label class="form-label">Select count</label>
                    <input
                      v-model="config.selectCount"
                      type="number"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div class="d-flex flex-wrap align-items-center mobile-gap">
        <div class="col-lg-6">
          <div class="d-flex flex-wrap">
            <div
              class="button-text d-flex justify-content-center align-items-center"
              style="margin-top: -4px"
            >
              <input
                v-model="isMultiple"
                id="is_bundle"
                class="form-check-input"
                type="checkbox"
                value=""
                aria-label="..."
              />
              <input
                v-if="!isMultiple"
                style="margin-top: -5px; width: auto; margin-left: 5px"
                v-model="siphonTarget"
                type="text"
                class="form-control"
                placeholder=""
              />
              <template v-else>
                <a
                  style="width: 70px; font-size: 12px; margin-top: -5px"
                  @click="handleSiphonAll()"
                  class="btn-theme"
                  >Siphon All</a
                >
                <a
                  style="width: 70px; font-size: 12px; margin-top: -5px"
                  @click="handleBundleSiphon()"
                  class="btn-theme"
                  >Bundle</a
                >
              </template>
              <a
                style="width: 70px; font-size: 12px; margin-top: -5px"
                @click="handleSiphon()"
                class="btn-theme"
                >Siphon</a
              >
              <a
                style="
                  width: 40px;
                  font-size: 12px;
                  margin-top: -5px;
                  margin-left: 5px;
                "
                v-if="isMultiple"
                @click="handleAutoSiphon()"
                class="btn-theme"
              >
                {{ isAutoSiphoning ? '⬜' : '▶' }}
              </a>
              <img
                v-if="isSiphoning"
                style="margin-left: -13px"
                class="loading-icon"
                src="img/spinner.svg"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            v-if="true || getLevel().canSnipe()"
            class="d-flex flex-wrap pe-3"
          >
            <div class="col-lg-6 d-flex align-items-center pe-3">
              <label
                class="form-label"
                style="margin-right: 6px !important; margin-top: 3px !important"
                >Gas X</label
              >
              <input
                style="margin-top: -5px"
                v-model="config.cancelGasMultiplier"
                type="text"
                class="form-control"
                placeholder=""
              />
            </div>
            <div class="col-lg-3">
              <div
                class="button-text d-flex justify-content-center align-items-center"
                style="margin-top: -4px"
              >
                <!-- <a style="font-size: 12px;" @click="getSelector()" class="btn-theme">Get Selector</a> -->
                <a
                  style="width: 70px; font-size: 12px"
                  @click="handleBurn()"
                  class="btn-theme"
                  >LP Burn</a
                >
              </div>
            </div>
            <div class="col-3 d-flex align-items-center">
              <input
                style="margin-top: -5px"
                v-model="config.bundleTip"
                type="text"
                class="form-control"
                placeholder=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <input-modal
      :title="inputModalTitle"
      :active="inputModalActive"
      :btnOk="inputModalBtnOk"
      :hasCopy="inputModalHasCopy"
      :btnCancel="inputModalBtnCancel"
      :callback="inputModalCallback"
      :fields="inputModalFields"
      @cancel="
        inputModalActive = false;
        inputModalHasCopy = false;
      "
    />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex';
import Listener from '@/helpers/Listener';
import Web3 from 'web3';
import { ethers } from 'ethers';
import Web3Helper from '@/helpers/Web3';
import Ethers from '@/helpers/Ethers';
import InputModal from './InputModal.vue';
import ABI from '../constants/abi';

// const oldLaunchWallets = [
//   '0xe59410f5ab13058886fd76023aa20edeecfba39b',
//   '0xB37F3a32edB2b4D2EF6fd6949F11615EEe20201F',
//   '0x444B15C818a741ad94fcfde1262A99348853F09e',
//   '0xe4CCc7998Cd0573a242620a9601757AF7876528e',
//   '0xd8471f3F0d1b9B0D023438998bB863F7D5349abE',
//   '0x60eEd04E2662f0bfB1aceA5F79AD2174a553149E',
//   '0x3c980F9BD886e6b6F6937cCcB62b6242334759ce',
//   '0xD98fcd0dBb7fD4491838221bE536e34CDC4Bb2eA',
//   '0x035e4F7424670D58E99F2A1663fD8867C38D6465',
//   '0x944e2A9B229691d5f14CF4eF29c087ec3D9Ae3B8',
//   '0x0633e07451cbCa57ad4dea7076Faa2515aD0A015',
//   '0xdF913168A2970376640aE319c3C121DD0cf08118',
//   '0xE38440B74f222E1e42Df1B52aE8d50eb3A3d9EEa',
//   '0x8d8656f0B9c9Fa9A85370196E7fc3ae828345aFb',
//   '0xA78C019da94AA6f13E2730Fc07146c9Dba439B55',
//   '0x084f9C022676673AC5a6d4828FF7ee76b0710915',
//   '0xacFeaa6e0151485427f0F972Cbf11D345Fe94cDC',
//   '0x44E8448eb3c33C67A8d6c8FCe73AE9D238a9Eb99',
//   '0x52de71914E2f6baa2761DE25cC3520fE211DF522',
//   '0xB4a39D10ac4bBA6E1Bcc91287618F647bFC5E8B9',
//   '0xE7cB2579304b31615851CfdB262FC3713C33EF39',
//   '0x9Bd403Ee9Ce5e3B59d36d7EFda7aB11283B76872',
//   '0x138148C5AC719e9B2Ac09C10Dbb7EC4b3466Cf07',
//   '0x6E8674e6cF1fC2000746484F990bBE22762F9337',
//   '0x93884c048A3FFa7EA19A91EcE668098F657833d3',
//   '0x0565D1382A5F2a7F4fbE10798ecf4b0a784cf088',
//   '0x2BF373d44c0256C53B59D270232e2588FD61C9d1',
//   '0x3fFaE2C8a21AA73Af0fDCDa96E2422962DE44CBC',
//   '0x10C75fE79603fB46Be150564EF27EB11abB4E620',
//   '0x12c0B3aF3C51AAfc8432de7E350eE337D496BF0c',
//   '0xB65499f28Afe1f045eaf170988ebf0747352993c',
//   '0x6df38834DbC114F0aC3952C559D30a72DE5D617E',
//   '0x68B569EF1F8EA19d835Cc02240B227367e90D8c5',
//   '0xB8205DCBDF8CabdA002f334c925d2815A903CFCd',
//   '0xEf451df6b8b3255A7A1b574392085f89A8D3713c',
//   '0xc1464116cf61F2D0A0d0c46259A18aa2Ebd1f95E',
//   '0xc9ad131879105f19b774542Fd9303ADe8eFD3aEc',
//   '0x95b78aa52279F0607B3F6257ED02b501eC686Aac',
//   '0x9424726864784ff8eFf65e12339Eff7024a402E9',
//   '0x96959e361344fCA952EA24a5AeA831f3391189C4',
//   '0x7D7C5B9e1F7DffBA5c36324C69ECc05a9063f6e3',
//   '0xE4457b37fce7E80b215FC9fe204398ac3CdA9a69',
//   '0xC5E821AD755cB7730b73258d3A5E71B4b7e74E07',
//   '0xc97D98cd3AC8E3C73c0e90f33C917DA6593Ef9B5',
//   '0xe1C0c98f6Cd7bFAD76BC630DD42744AD2ceE3B05',
//   '0x5Ee18cbe2bF65d0516843d7f36538F6b206b09bC',
//   '0x618557B3dEC1Fa5EF0016F630F1845aCa6Bf1c20',
//   '0x7dCE284A736554d2DCcf589eDa84DbA268cB3D61',
//   '0xaB55595f80eE6f397fEbB70d3118026dd839EDe9',
//   '0xDDc997a42A784f9A5c818919433aBb04983bC5E2',
// ];

// const oldBBWallets = [
//   '0x89c83657ab99303568053eb37a573d5d8c07c018',
//   '0x681C9770CA90Aa2095A599Fa3Fca87CEd44529c6',
//   '0x2A12Bea0ee2F848cdc3d3145b87a9A69DE78006e',
//   '0xB402713B6fE2ee226EE9560cCC223fB6798474D5',
//   '0xb2a2Dc2637d81B5a4d2b90D1f256bd01934F064C',
//   '0x0a6885b66C0e22D09325f8BF0b2Ceb02AbDf9bf0',
//   '0x6DD63e4dD6201B20Bc754b93b07de351bA053fD2',
//   '0x1D3FDe0F821D7BC274064646c752875F5c747960',
//   '0x96bc98Bc0794Fe084B11948De747A774A82a6a9f',
//   '0xA24F6F31665dd3f79aeEa7A87b21dB3f71c47517',
//   '0xD2C822d9229D6B42459b41f3e556982c746b2B42',
//   '0xe5a13a7D1CfCd0426B0DD87c3f3bef412DB46C86',
//   '0x45c26fD77Ebf6bFbd90Ad55737cf3a4878F611D8',
//   '0x1469842D14fe3f7B0598B76818d07f83C477a34e',
//   '0x785Cbc768e812D4b37f052f73CB01F6786e328B5',
//   '0x7119db4Cc9c457F3E2B8Ad7E2d6E735932aA8a06',
//   '0xFdF8A8009fDc6bA13f554067EAE8B8FFAD8C5271',
//   '0xA393066752dF52e2EFd0b77638D81830d3c47C63',
//   '0x72a2c231eAE95E2f43217591657B32877E6baf66',
//   '0x930A18AbbE7Cd737248D7E4FBEf4B276F557fb81',
//   '0xD4209C5ac667A7d1b57087b6dD54d5C5eb1B8d60',
//   '0xED5640C38c0DC14F2d15E6B82e2C035060152990',
//   '0xB7Ab5E82Db1b27ec322021c5fdCE6ab8FCdcE9a1',
//   '0xb25EB275BA0F95be8D61ee54092773385bA0E4c6',
//   '0x36A49cE68e2ceb4c7F8659708A4F4FD19B0428C4',
//   '0x9073a5754F4fBEdb73E269fE7b35Df64aCCec0Fb',
//   '0x2cD88134Ed340Fd9D9b96BAd1A49f7505D3728c9',
//   '0xCfC904074CC1a3806e87fbCaE6fdF0B7086A2351',
//   '0x459ea57EF169338062593E16A42cA3836B7f8bFA',
//   '0x927BE1d63F0ab6DF3F3BdF9A77dde54f27b3B201',
//   '0xA6FBa84b56157bd58239Cf8c7EA0c434Db2bEb52',
//   '0x3E67041C0567aA812af24Cd6658370Bc8C85D1e3',
//   '0xF42ddFB75F61e0327Fb955C45B213A7E6Dd7873F',
//   '0x31a231a951DceF5efBDD31f8dB4D652Ec9d91Fe0',
//   '0x31d128152BdA31C2fDFC5ae93C7CAfE69ffD3dE6',
//   '0x7e82BC4B8e37Fd274D1C039C4A21653AdbA28912',
//   '0x2Cde57b2Ed2E3a485ced0e3FB665c23210a2b19B',
//   '0x7bD6eaE2aA49Bda5fdD4c03c7A28efCFc1fB8663',
//   '0x9751c61DB7e50EA5bb2f850410cb741d12304ef7',
//   '0x9f0E34F96cda80c60035af3D911282968396Eaf3',
//   '0xbAb343D882D42d02026Bd849e0b33f60E10E4286',
//   '0x928FbFB6135Bcae9Ff452e277f130413eB2A7033',
//   '0xBaEBC9FC693F5D7fB7dD24A1cf9262F7cf3a080B',
//   '0x817B45D53fF3883A427Ec537AF32f796D313da6D',
//   '0xbB4F3d85E368372A5deE6eb130cb39C4a3C92e8b',
//   '0xd4Fc8533b1E699947647DB0A91EDeD34A04ecb52',
//   '0x688deb4Aa2fe1a4D8d21F7B144312078afDEaA0A',
//   '0x34B2AC36205420f128553879866D72F1f3d0BAF8',
//   '0xCd72A04312823737454944FAd1A85E80Dc3A635C',
//   '0xf594B7Cf0DC77ae2e9609D86BCa00a89a7C128D3',
//   '0xA160C5fbc49dd8b43e9E011ecE59F4A1D1eA5081',
//   '0xA2EEeda4b108FA1dcD0E1291b1cC920138d6fd5b',
//   '0xA5Ef6990D31365bbf362b2D60a434AD059B17Fcc',
//   '0x3381bd20976ceB8aaD1D7Eea712F869a9C23DEE4',
//   '0xd3285F4D41071472F0E56fd059Bdb0BF640D3359',
//   '0x5950B22496942e202311Da2Dea92eb85501D8a72',
//   '0xEC05e2834575fA453DbCBD052Fd9E7615D40F4C3',
//   '0x3ac55f1b986da1f6313826BC6c0B02B3cD2831Ce',
//   '0xe3bd54A4894d5dc26589d7f3B6aFAe57E8DDCd22',
//   '0x6aBDcF8D34bE1db5Dc22D787a19216d26687633a',
//   '0x7505FdA7B989fa120ED75684b1184108970839C2',
//   '0x9c33ebD1329f0C7832112141f72714dD4Caf48Fe',
//   '0x9611365Cb4c5A2fa2D7143DDB4d3e485EEa8b0B7',
//   '0x976fCA899d8518952Ca1d95b7bdB58C96eaa001D',
//   '0xaD5bECb4748d56d7C658f34D46d12E2a91A7aE8B',
//   '0xbC2c96681b04404Cc2A74331e09ED7b44545C2F4',
//   '0xd368D4718087963Da1E74f5c6Aa7879d4b3ec0dB',
//   '0xd1Baa761ae688a735e8620DCc66575eeC0925E85',
//   '0x451a4FD0e99245957AeFc2ce9Cd5bb7F88DCA807',
//   '0xb0047B49455CDfF773dDA9C1820b4d78DFa4F6f3',
//   '0x491AeF3Fd507fdf4460cBc62b256eC29F84d5415',
//   '0x9635718DE57CD803E04935F66688CfDfF71c84F4',
//   '0xB16247b8d908CD0B9C66d4F27c3D961D7646545B',
//   '0x9e91e378720A0F1FF5c2Bd0C8D8055daf23A455F',
//   '0x9C6b15f79ce6ec521515D463Ed385004199df255',
//   '0x926a9D4868cc1C5C618B4E1e2e9a4F375C15E741',
//   '0xf2C1Be789b7b5699311EbEA1987338703800c608',
//   '0xdd79B95ed28BC056509D52991DD191C0268f6980',
//   '0x2817e94477eCAe683a5e6b2C8c9b312C3CB6cCed',
//   '0x30a0710dE6eCa057e85A9e5984309E1247803341',
// ];

// const oldMassiveWallets = [
//   '0x384586A1B9Ee226C32fD201Be21aAed33f61E0D9',
//   '0x3510e89931F49c33D492DE7b6F6cF3c9614B380c',
//   '0x195fd898E4057f5c25C0Fb429cFbd63ddC10f4b0',
//   '0x91536E14aFab54b57b68E9396a8A1A79E9cA6540',
//   '0x030cf0315C3974b84F8378a69123ffe42D27e0e2',
//   '0x5D24041951D705b4007823E14f4BE4CC6cB3F1b9',
//   '0xb67B5C5e755Dc41CF047486bfE13f2E6Fe2c8401',
//   '0x65a2cdEAE599Cdb48507B026EDaD3f7B2Dc4ea06',
//   '0x814581C480D6EA1BB286e4502eB0cE5b6Bf179b8',
//   '0xC28B8F93b14A4E5143741a08aFCDaeF62D7aD23f',
//   '0xd1675CCc1E7933DE09E2A10061242E7A8B2BEd00',
//   '0x58e55A75640bB61B7f47870903EBC11A30Cb58E3',
//   '0x08D955353336b455530716C9C31D985380b5FE14',
//   '0xd3A41dCBF1Fce8FAC2078AE428Ced3d117FdE755',
//   '0x4b3aD466BE94A251555866D40F18f6B252Ae679C',
//   '0x443302f7aa3b58224909Bf4EDD02f47eD2AE5F2A',
//   '0xc4B464520bc64033A272c9BED215d5c0E87d3dC8',
//   '0x5B3E516a9E5694b3950b7F3bCF676452916992Ff',
//   '0x8502fcD2A3626117Aa7ec3b909EB4A2DDA1cF7e5',
//   '0x6dD792270631C7AB2B5A08c8c773A96852802825',
//   '0x3F6DeE24f2b7e051926d50f79f65A09289B45494',
//   '0x3A78940EE200266795062e87A8bC213509a6D6aB',
//   '0xfeb60111cF563b6FB5e78A54e19A37B7deAdB5A4',
//   '0x2D15a62821780D0FB21A8b4d017AD75a89e9Fccf',
//   '0x08433d0b0Ec02479f1D7B6fd70795C6107814A99',
//   '0xa694dcB4d45D1F6c5c5206fbf7999E5b7476d3Bd',
//   '0x58a1CfFb894bCbc433E95F18045D6f821e2d2A28',
//   '0x059eaEcEE78bd65EAbb7e7A7794e26E89e54Ee64',
//   '0xc568905A64A2427235eBf2108C56854eC1B77Ddb',
//   '0x36CF0faefAbfDe18A54780DdA8D20C3676Ea7f8B',
//   '0x8381E21b043eABa3d53f20d4B45E7c9fEBb687b2',
//   '0xD0A98a3e185f8B8Ef57C38381CEFDf3933217896',
//   '0x4c5701FA6B08E8fE3CdBb98478C7ed3e44e63341',
//   '0xEdF0ad5Ef74A19BEB8292Dc0FC1e7DFaa9f88704',
//   '0xC5e591364ea6969CFfaE310f5c9f9F914DeD903b',
//   '0xeCB4FE31Ee66Ec9e54b223F480E336Be06e23EBC',
//   '0xb9d983EdFE0ae943c01d2c0Fe96739cD7D880c5C',
//   '0x77F4d44Ba455BC938e5164dC3070B012f37012b0',
//   '0x75fEB02541144c800F81345512FcfdB90e9308cF',
//   '0x95Ce11493275dC08115c4b61A802CAB366D4917C',
//   '0x1AFA98BfecC7dd88C7124C36B6aCc85f4Ae10d49',
//   '0xBcD0dDc7B0844779add2A76466C262Ee18D124F0',
//   '0xC8b5234DbC373692cc3e30Aae6C41ac6d13598f5',
//   '0x11A6b733191aB5A932B83B501a1551A1fF7415Fc',
//   '0xB23109B0D60c96051f7AcE8A7322949dC46Db235',
//   '0xd1cc4F31A549B7C9AbABd996ec7ee61f2aa5c365',
//   '0x3D12978360f038B5Af1A48c91eF942857c621402',
//   '0xae8b6adFFEe94818946DFe5eAd0d5789EABF5f3A',
//   '0x60d70B2463F146Af1694FE213fFD37991f7326DE',
//   '0x2213Cc2eFd29d7Ad2e502a918BCbc48d5AA2B2C6',
//   '0xaCFE7B848128f41C12f3d40A7eDf64A5Dd7AFfE5',
//   '0xA15B90e6B17C433Fc9adC13944c8BC73C5e98FcC',
//   '0xa7243e979B2Bae3E460D153793F3530679e6Cd0F',
//   '0xe55a284032CdCEFAfAf59ea73419b0fdab7264dc',
//   '0x1e7e1511ae9b94aa9eBC174b5FA016989a4150aD',
//   '0xeC4eB60Ff687710e7D958a1A82e81235BC0Ba5C6',
//   '0x6d8F42e83452b30C1494359081aE832b88D9c7a8',
//   '0xCb831849Ac762Efeb53dB7923D3dc8F48F09fff9',
//   '0x323B2DC521a7D631dF14E34e4A4b4301851d062b',
//   '0x19C193D5401BBf67813d28E1EC3bfF54a6a0bdB5',
//   '0x8Ba2d6d510dF2789965EA8437b97c5611009cE11',
//   '0xD71DcE4682823ddcAd8411A1838F1d75A0f52200',
//   '0xD0c55B43A33CF59974aA617Cc4c40C3B476fC5Ca',
//   '0xd3a61C92B5e1B1627F4d6888cEcC1Bc85Da54f5C',
//   '0x1E14A0969a2b2612c47082425F31050F8389FE8e',
//   '0x695624602c9c153Eb59C0cBE308F2E95465031BD',
//   '0x452eEC79Ba32A9091dBFaA2349df0B797d336a53',
//   '0x60a58c91A62B93c6261F66f6807719b217fd8C6f',
//   '0xdfd56F8895925C42B6BfA9300e2ED138A579875F',
//   '0xD1aA9Ad8bC25192B99fD487743747463C4ee3B4c',
//   '0xcCb9cCc9CD9E09BFABaf30ba7196C5a96a4B2700',
//   '0xcfa6e2068EfF7a2AD2F9698aFC800aBB35C53Fe9',
//   '0xD7ABDFbe1A0Dda4bB97EC6Bea0A204eA9B8b5091',
//   '0x767f50fAf44565B07BFabf47C3cB58Fc35DFACAb',
//   '0xe1584fAC35Eea236947a1dDD74cf1b5E1E8EF64C',
//   '0xB2CA4F0e91001B19bD8f469F4A47059eF0883630',
//   '0x6a4a429a6947dC293B36bC720AE20dDb0cDc58C7',
//   '0x606e1C3F56BE37D2699271E1A7968FB860EC9674',
//   '0x12e5F6a25d6E3FAa8AC655E008542629eaa4248B',
//   '0x5A8a1181f2621A72c6D863BA2D61046BeCBd8E50',
//   '0x7086f0852Ac7D1273f64ef3138b57904C738F2aB',
//   '0x5b3062F240126e367b7dC95288FcE7b0a4593d95',
//   '0x921A10F42DdDF3d5078774423da4a99010F4562C',
//   '0x4aeA886962C840deFBD7bcD22dE05D9b843F3439',
//   '0x3283aA65f35915a89152F312AAA411eA311a1D45',
//   '0x093C1dc3b43eF7c0a8fF4417B748053374183DE6',
//   '0x6dEe93C60Cd1333F94C7a35D2C7653Ccd9d48243',
//   '0xb9014d22a90cc6C8D0bBAF9Ff527E694498e0B4B',
//   '0x317593B8EcA21AF4B0085F34C1bc9698bc2144E9',
//   '0x12DE55aF4c9B4ED4CD4F6016CB07a286719f33C5',
//   '0xCA988420e638C9d70b2E0856cb87966af37C2E7e',
//   '0xe08d5aC4C106652aE511d1a42D6b1c8De40CAAD4',
//   '0x70f582fF46Cf00D66A2e45ef570BbA78d874EFa3',
//   '0x43Be64AE3dC08f8A24350F49128b205c6e6AB8b2',
//   '0x25A6E0dC6a04c4286bBF8e2C76Fb6bBFC5134e9c',
//   '0x18C52D6AEF4839F5E93dE6b7d2F1fa92CFb6318a',
//   '0xE2e2784FbEF33D7009982a0799af5394679fc118',
//   '0xe03F33D459541E1FeDd9A886a5E4c21381B00524',
//   '0xeAeCAf4Fb0CC5B7bA7Ef2657c5E6e901C718c468',
//   '0xef3Fc01cbe41b86877A8C09Ce2BeA3FA59bC2c25',
//   '0x50f74b8cE7cB8D5a6d58Fc615Cfd9EF2Dee9209C',
//   '0xB37Cd1982456e03d59E5Ec6f9d4ACa4dD473F48A',
//   '0x4ff547bb04667f2e0412b6cF0A368A181627d51D',
//   '0x1e1338Dc6840B9875f20124f8aA989B429A4682F',
//   '0xC3d5d54BfE87a3f2b3FACb02329255AF5B7b2251',
//   '0xb471970E4DB84d0dc5aD2D87e05d04Ba91541e1A',
//   '0xe58F8E9B0730868736aF33CeF566868CA3926DF9',
//   '0x1fD480818206F0b8e37d010B61a4A5605FA87e28',
//   '0xC5325104FfE08eB8fE01D7d0A24605d8A6dbfE22',
//   '0x66519E482FBAB09633E15A2bE63e8399dDef542D',
//   '0x35b9370C8450f4f34B40A9c62337B88c9Bb00FEa',
//   '0x4CE3C185BdB00fc079D26FB51CF2B2e0B579C04D',
//   '0x9504D18AAEa4d73Fc72A7041aA71b4f3c05147e9',
//   '0x12D98BD97Cbbf283Fb9D56622a2CA72969B60439',
//   '0x7052C311019b9eBB940fdC3a83cA282A0910929e',
//   '0x07edB1c6fB6A2422a06BE9D2caC20E903a8E94D5',
//   '0x14f2FAA84E975834f90dd4549818D3F39f28F2db',
//   '0xf167FDC5debB272536b29af3f5407ABe60d7a09f',
//   '0x2aF928a365f3a7D3D0e8d094fDF1e838e1935D13',
//   '0x7795142ad45DB721E67fc3966574248Cf60816cB',
//   '0xaC781581AFD342Ac90f6d453F4c14224AeFd82BF',
//   '0x62BaAB85b6bA618956323cB44b35492c005dCCd9',
//   '0x9F18Bf9548495371a2390101E17Bcb361979711a',
//   '0xcabe1714aF88942d4B7b88C4c82B772601811534',
//   '0x86CF242ED5B522425113E31243FD967Eb229f4A9',
//   '0x3Eb875337427ea2e2aCa7631ffd1537DB844123c',
//   '0x5F04289Ea4EC6E663862e3e5ae8ca1b163e93012',
//   '0x0ee860724ecA6AF472A06ac5DFC048dBF335779d',
//   '0x1F7d01D93C9b0276976140661a687c3a9CC3858d',
//   '0x976f3F41f22b95CB7DEA59c080d3f3f152bb8554',
//   '0x40EF2E640d4D950e5b4bF7C7a71F6d289632b135',
//   '0xa2E43C7d2Ec602011C166519447119bcbFe6d0E5',
//   '0xbE56801dfd83c3d05dEA7784939A092c01bd7131',
//   '0x28eADfC194af5f3DbBd7eCAACfD6468508B2A2E2',
//   '0x26B8E429303FAC9ABD3Ad8E916Aa45eE11357eDf',
//   '0xBdaB78e824cc2FcF00F6F19922a23Cf8AcCc9DB8',
//   '0xD6c0DF3744eD17C5f4511CFA4CAA8Dff9C398A7B',
//   '0x119AAAde585cf61c3F27aaC1EA091C23e838ed51',
//   '0xDe9e05bee69D76ddB0D887ef95622aba06991B86',
//   '0xC44c3B5f5004AaCBDb50398A180531b64De6C13a',
//   '0xee1ed1C9D16749EBf1f61eD8C8dD2510fC5C154f',
//   '0x0a6553b7a2562397329E023aA52e872422B751A1',
//   '0xBa005E6867B003B7074201046fE1D4Ae85722ff2',
//   '0x30767e8e0543ED77C9079D27E23C8f4E81Bc9A94',
//   '0x2CA59570CaCcE9661b187D7036573e45F32099dA',
//   '0x81F6CBF642D540b87e90C0e47582e3784379E8aE',
//   '0x94b6d71502CB23540A36d4598dB53600DA9ED150',
//   '0x1f9059AcC4b214D0Be7A8A2dFeEF61CD41091FF7',
//   '0x39A053BC76F3300145BE7e30F3394D6c47607F9E',
//   '0xF8Ea9f9945Af253c1D0416a6B8E893c1583233b5',
//   '0x93C4E220A02316E27B95fF42b51F6472683b9236',
//   '0x54F0277b833BC74085A953b5352098d0893020e5',
//   '0x851519728eE87843bd653B8CFCFB6a3fd5cE03Ed',
//   '0x480D03F99E65B9D485400AF8462cd137A26E2d9B',
//   '0xE8180046f455cB39EE05d1e03f00dd025d2aCF6f',
//   '0xce63ee51DbBf195Aaa4d63D2bf2A71e0611C3064',
//   '0xff660108f22e4b003ed5DB7c28CCa7389979CE9F',
//   '0x847039E6001962e69d4912d8DBe6Ed34ACF3fc35',
//   '0xc0A50E335ABC22e4B434DC9Aee83E9A55Fe240EE',
//   '0x2c291E268D0877118E1D9234448F5694C7D33F9a',
//   '0x83a5201406DAa3958B69efcD853c329F06ba89CB',
//   '0xa3FBB9B3680CFDEcC6CCc1a9B6De9B369c81abd5',
//   '0xeD937428B50E67120Ad2427aA0f069B050e57471',
//   '0xd8B2B31F8cB94D37Ef65c5030dAEaEf7578230fA',
//   '0x6476a8F6699Fc82616378506754DEA3e772788b5',
//   '0xdf1E9A812028542f36fA11534d1A8B33583d0622',
//   '0x8547e8E3620cfBd3eA9bC0062868C112D76f18Dc',
//   '0x46C21ecd77cf1A0dC442Ba3FdC16177Defb3E2fe',
//   '0x9180c78289cb0e038206F63e085F1535C497e5Ef',
//   '0x49Fcbb88763EC8d399F78a614eA91BfD71d5F871',
//   '0xAe0Da7Af2B017038f646B8d2E91B3820eDCfdD47',
//   '0xE1Bef94bF2fD1AeCE846a984dCd4f7399ed0ac38',
//   '0x30915AAaCA028C661985A8174C6c47744cb62ec8',
//   '0xe5dc20D22A5A61902772a4973355e639C0CE34eE',
//   '0xC643473C716612d0c5b39d3137e8F24c63d8403B',
//   '0xdFe1cd302D03F2B2f0de49989B0EF07cE928d246',
//   '0x0596648c9f223B7933CC7d7450b31f25ef991482',
//   '0x0934A5C72c84a5584653B1e161085470692162d6',
//   '0xC873cA2b5f8f4952e1aC07B036e1D9e6d1ED2065',
//   '0xe2B8fA4d02b6c76C788C02464D6E5264f2d1786B',
//   '0xB91a0965E8D28DC3C1973459D9B5887E2a57920A',
//   '0x714b994F4aF1022Bc8D90B08D237177d4598e009',
//   '0x25bba291c015Bf09356a6e8919fB5F96194e98b6',
//   '0x32f1A7fF36550a9A52cBd6761ECF6b65C79F3690',
//   '0xCc720cDEd4f8B08557F2be8Fec7ABfB0eF7D3e1C',
//   '0xdAD46773F9BF3c0719e79D727E5B35CAe97e13c0',
//   '0x62738Ee66FBd08b700224A413D506DF0506EE549',
//   '0x5E1793a57E7126111Af9eF1E92343C7CDC45080D',
//   '0x701D1764A3645D176Ab82333bed6f6839b2D1D53',
//   '0xe76dF2a4fb98DE2753C9fEE7417cdC03D9368503',
//   '0xf502c02E93330c8111a54368611A31Ec0933a908',
//   '0xea40271860b835e53e0209461956B9ae0936cc6c',
//   '0x5A6cDc22E091cABCEA79AaC6523222F591D1C91b',
//   '0xd878A350C02792F81487Ce0dea3505b639EAAf13',
//   '0x50aA341ABabf1004C0d9B69eF2E01357e0596833',
//   '0xC17C1b45659537D66f6AC55c0961cc22392E4631',
//   '0xB7247C0aA1c10b94C07Fb353ea9A5F5544Cdb697',
//   '0xE805B1c7FFe89e1960a86e3908FD5f2bc14b597b',
//   '0x66895A7C5011ff47D24B852916C8dc1A3a055e17',
//   '0xDa86eEc2Fc204Bb73874524c6490D198c8518D56',
// ];

const tempWallets = [
  '0xb548e3dd23236f7c227f12ca75ac52766b206c81',
  '0x83c0a5A69EB9CC245CDb51649De3ed2Da2529118',
  '0x39D6a2fb5f7bf1fBBA5D5ED0836FC0d33BA4EE85',
  '0x5cfa2337c0fEAEa7B4071359d90544F85d8029D8',
  '0xf7D777aa1c740204B5A290A049c0f69061ACA23D',
  '0x22f6eB0BaaB4b2322083703a6D4B09CACA617fAD',
  '0x8E23de2eBC4d0517c14d1F4117e630D157c80fda',
  '0xd988528E2F63b7948A00205eA746e8B4b4760414',
  '0x48eeF63cf518494A31083721Dc4e63035BD56511',
  '0x8831c184b22A16cde033baE2dfa4124Bc86c4dD5',
  '0x79E6D706195FC9811DF71f1671353787D593c23f',
  '0x57e63BF1af0B0035D0AC4d755818fAD2010F42B7',
  '0x6e349211f55AE423CAf5bC3EDDad31F8644f0acc',
  '0x3dafB6Ee48b16f7d07EC9315AC5D347DE1a017A2',
  '0x26575EE39898Ea24F9260002b63aA16C0bdDF243',
  '0x8A28D0C203888Edbcb428d90Bc19CA14f5da3271',
  '0x1427bee5DF7D864c80f813f9a00Ebc98F5F613DC',
  '0xba9dBd2338357EF2544DA4DB0BAeE18614c2C2C4',
  '0x26786e15826E010279709d2f813CDBA9029e761d',
  '0x502064875Ef1802773BE82eC8e1215c789705306',
  '0x4565c71F223bBD64537ad6426b2996d4397C3c88',
  '0xf044FD621FB51C99C718a1bD256B79d6C6ff5872',
  '0x7CC26606e628BB0e44D22005713210Cdcbd36865',
  '0x303920b10588153d6b42679A4C13ff49c675B6da',
  '0x792e88551287EFF5825b3000ecbB63F73AEC8d3B',
  '0x29Fe1a81858783Ebc60a7161d304a545E8c859ee',
  '0xAa3fb6298aC632a6b28882C3bb33F6f90E962409',
  '0x1DA61BD1D29172F6e422D8be9e1daB348987BdCD',
  '0xD65560ed7E42090Ce436E1Abb81645cEd86d63A1',
  '0xdDe03fFDca1152c96763Ba8DE8fce26F51cE2199',
  '0x1Db584cC1967CEb6aAbcB54371142a0f67C14a98',
  '0xb2999192B2f83243388b14Ab6078a95a471d96D4',
  '0xeA87023cA00Be9cB0Ddf186c44D51dF5Aac091E6',
  '0x38A37b01e7327FceC14bf8A3a03902371DeB2D20',
  '0x0149406468D86355117d58E96a53f4FC5B6db92F',
  '0x90d1b955E54D7fd4BEf118997691FaC25ffCb238',
  '0x1DfC0eC5a33065B0897bD33A59De27A9B389cF14',
  '0x8C36dE9b609c7db99da6c7D0438663C854030A20',
  '0xB76C9d9917a3C22dB962f781D7804F311BD612f2',
  '0x95778F570a4fe2aFd18376c922C02657d991dE53',
  '0x68E23F05181F75b37E497aa48F2EcADd4B76eF6a',
  '0x6aF3DEafcE4e48136F8f8967AD59359D8eF1762c',
  '0xC50A355dbFfE9800F00040bE43A54B8E24366493',
  '0xeb2C41B5595E58270Ee09Df1e37c6D9047963D7e',
  '0xCFf86c09ADb1A39105A65210Aa9bd411f91237e8',
  '0x124b46c81ef976bf904941b98d72e4e118e47392',
  '0xEA1DBd5b0772c6823189F6A90DDf7A6F53a2ce38',
  '0x589E4d29bC14F3D0905ad3E4356C58a631dCe3A5',
  '0xa43E528f8eb56380B43706D4694f552C2CE28467',
  '0xC457cecf89ee5C4e1b9399aeaD343da31B694482',
  '0xe19E12638F7D3E5F5549e340E971aa38CF2d34C7',
  '0xe0416232c525760D96368a5B214f2D9968b46416',
  '0xDf8535035c66581EB6ba464F4bdb2B925C7Cf988',
  '0xbeBa8BBBE7f254E5fFbCc5B3a3d29Dbfa3164875',
  '0xCe62D7cDb029255BF1370b06b6E93fA6CAdcf30a',
  '0xC86cFA4231EF3ef36F1A2208Ee9b6E297F233a30',
  '0x29BB8d50c4e9BC352F69cd4b2fbC36011A3B6440',
  '0x6851cd7933c532EbB3769904fDD0B1b3398fDF4B',
  '0x70845EAc1410174EB12cBCF47339C7dcc9B115D7',
  '0x055a01174ff304fABFede214C11dfAC16Fb5cBdB',
  '0x9E01a830332AbD87cd439Da0FAd405b3E13b8e82',
  '0x03ca2667D8c6347C3D7D9a78958FC53253A54f21',
  '0x07fc67C013dd81b5d19ABEdB89784FB11aFA70D1',
  '0x4CEd2d2b70A7FF0F21c7C421bAEBdF38984e860C',
  '0x79E3955957a3a056334490C220BDd0a6222C0F9c',
  '0x9106aF1F0b641065B04E5d71091d61F02fBC6229',
  '0xf4cB821277bC1a62e31f39e2dbdEffbF5F3DE14D',
  '0x0A68891eF45129e58b4EFa72b77a12265648d67d',
  '0xb929C647171Bf460c303D369Bb260bE8Dd4CFdBb',
  '0x26296dd954265920C950fd50681E0609285F44Ce',
  '0x4EfAD66Dd1E3Af33948aF4F15bc866EBB6996CE7',
  '0x94eDC0cFC5B2020448DA893a03E70Fad79ae80bC',
  '0xD0FBb50a9404Aa08B5451Ecd5E0430d1F2f3fA90',
  '0x3331448a01cfe962e0a8405f89de25FB223eedEd',
  '0xB42C13A852f503e8B5A629d50F9cC7EFfcDAdc41',
  '0x397eF49094cC39e99f72a6b9269844E96A4F5aA2',
  '0x014845Be9BcFC5135b03B4111f3FB05A7355DaE2',
  '0x36F007CD1F1189267B84D78B4CDBf1e8fd41A27A',
  '0x23F50b23E1be29A40fe8eDfaf0f8A9E264F9Fb4d',
  '0x5baf2703f4826f86ca3d82F22587DBaf3238b2b4',
  '0x164c422D297fF016717ACa8aDCd4809F5e8705F7',
  '0x080B109Df6aF8F5773f1a1A16D23Da138508B7Cc',
  '0xFca0dbAB9FC6Bcd79b3F44a10d6c9589E73f706a',
  '0x08338d70E9283c79af432e869c43fBc406faCfcF',
  '0x0C414fE945538803EFCed4FA47a17116Aa5859a7',
];

const g8Wallets = [
  '0x9ca34ee17891fb7c57979639023fee441c820a70',
  '0x4cD61D320d553bEf119C8935e099A02f0CAC19aA',
  '0xbad6EBf0cBE6f016EAFBb597b23b928B6FFaC710',
  '0x75572D2A3E60E1011B70cf61D285ee19cd8df7FC',
  '0x16C157220db603977AcACF37AaA024E1ee145dC6',
  '0xdaaE143670a097CC1e438cA20332369b757B3C67',
  '0xD558C09F9E87D42CfdbA430ABf1b0159f7D5E31A',
  '0x41aFaDba8407aDe9B38e4474c50c55b090DdF9aA',
  '0x3cF2c409De71701091B83CB509Bf21c8428C8860',
  '0xEBd139F1631eb3A690B5Df788D7aE0E0FC681244',
  '0x5Cbc6ea873dE2bc51FD49D69171dCa12b1b84530',
  '0x92d2803404576bCD025D0a32d8aBCE55f0C79cA2',
  '0x184ad8de464dE2f3271620bCdc36d029AC241A81',
  '0xf4644C0683B00B8c63cbEfeFF2B9dd7ddCFf416b',
  '0xB9b9DD8DF868e2cc1250bC7906a377D10892DE9E',
  '0xfA76a817a884a03173164C7A0953970F77bAe227',
  '0x9d818969F71A2aF09855b2C1F8126C01692642E4',
  '0x02abd012b6aB272B2264B486696777d4fd395Ea0',
  '0xa8c99991fB994498B1DD35867e3C542d92Ba7Afb',
  '0xb5963Fc9161af8Aa15bdd052E5B549281DaAdADE',
  '0xBa80144a73fBB53c80058E4a1A45b873A2303175',
  '0x904d327ae6d26e2e42AcC1ec29D50619774C7B67',
  '0x1BddC26b14B9A35Fd2b54FBCcE25210fa802E5a7',
  '0x781DB3E9a704a0C2Bef0FA1ea11A9972F6b9B2bc',
  '0x0EcfA570055Ee50Ad063Bb9d6d88f0e28bcCB7b6',
  '0xbD2008d461d13f021d852d498BDd6Dfdd0EcCAc7',
  '0x0e2bC5f8925DFc2E7372ed77D867357277E0275C',
  '0x66F06f3726508914785C1E773a87B3c331A61E42',
  '0x9C6eD0B69D721901BD67dfE4632397FEdB22C338',
  '0x275b72976A93cF6C23680eCfFd22095595e9b825',
  '0x514A7989d58B31C52d46153A628D35200356da6F',
  '0x4D9dCd879c95B36246B4514aF21C6332DEeC4410',
  '0xbD34Ec175234EED3101a092b811c50CCb3B23450',
  '0xB69c5d7AB41A7457080aC25414c0f34c688BB618',
  '0xD4D826FFb7d554Ed85C7079674066e654cAB72fb',
  '0xAAa234EE99A7DC86a124d3FeDAc95FC1bB811F69',
  '0x6c58457C0B3Fb23b6242fa2473E7FEc478Fb2cfc',
  '0xd95c020749b14CDc6Db36A40b5BFc2e4D731c9F9',
  '0xA3ee84fE1Ff03ab996A274028472434D0f7088F4',
  '0x2ED85Df8e8f29cb210CbdfA113A05B6d0D016d64',
  '0x5b4c0e1Db3fdd466155adF2c396152beBe41EC24',
  '0x760960140F38C89A6c774c9F9156cB0270241fc2',
  '0xd1307C435FC1470b06643951a86e9dAE6Da12a7C',
  '0x26Ce43b3dF0C323D12f6775fadB2340E94C410bF',
  '0x3973535E26ed826C7348d1AC9FfB0528f8D0bA04',
  '0x2B3ccA833Bf7CeA084E279381721111297Ec41E4',
  '0xcE3E72a30386F13e20119f507f9B2a1a89E62134',
  '0x0751bCAf730aFbc46D40a8C3cB3E219841F7aD9C',
  '0xe0b78deB1c66B59bfCfebAB3D3135492a2D797a2',
  '0x7838e36c8B50bBb7490187E67A0D613C7862442F',
  '0x000f934a32e0623cC30AaE712920C694B3151e7e',
  '0x0C8ecf2BbF9361fa2DD0Bd29ea473FB790aB7fEE',
  '0xCdF40615B111739Cf294d5626654BE174a0a25dB',
  '0xc46EF7f2aA48d738c3C9EfD2E77a98acAA8FBA37',
  '0x84eBeB9DE07137F199e31B42C727C359c290B089',
  '0xCAA8A014Cb03FaeC018828820728Dfc6dF8af89e',
  '0xFC2536Bc5BC4770197097C0E4fc066026D01f27b',
  '0x80F251d0ACD6615543F36672fBa10635c79caF2b',
  '0xAb568E11878634f50aaf9C1306eBF8800e259993',
  '0x57b734b3D6AD1B911c64C8a2088c3f0E0b899D0c',
  '0x6D44b444C0150A869ADEB07f463881947FC30F94',
  '0x3466194E44589354F95B8802aEEabe558eb5519f',
  '0xaD2b3412B8c73C8558Ae2c83E1150835a599860B',
  '0x7bf57edF1053Ca70570d12b63b6825ec315d8401',
  '0xfCb32cF168409c2947eB09811355665EB2F58A7E',
  '0xd7002ce962b1A9aeD5aaAfA2192cCbAC5e642CAE',
  '0xD91F03BEb1BE0cf93BFE4d407889DbEa684Eea3f',
  '0x4efD7a7C827e29CC213F172260A7E40088253ec0',
  '0xe943B5306F26C4FfE53C53cED38cDf144121Ab33',
  '0x6878Eec48260a001277e47ebD0D76F81112c3FcC',
  '0x137E35761cc36bD8520e13af65dD657c6805318d',
  '0xdd387634DB6ae343c8173C8e8d05C10198064FB6',
  '0xd794f572Af6d46519b1c0e9d1E9C19BDc014e609',
  '0x59cd4B3c1DA69d90b6d8373e20830321635f161E',
  '0xE4Fc3e464e28891152CFCc4abD63DA9767E51922',
  '0xCbBe1D2e349302F869fAa1da838a537fb09e0155',
  '0x217d3b6E495e884a4ba7FB5Ff72a2015bE1c4bf0',
  '0xd81F0C2922b751B710cec573710496F6f2F01cA8',
  '0xF212Df6796C1578b899bdF25f694eEc3600dDd7A',
  '0x64Bb8E51dc169E2024dc6534f313E55Ba167a2f4',
  '0x5E859B7eaEb5dD7011Cd9e6322b6101f265B4868',
  '0x010cDA27860d219ebf43731B6aaDD267cC45573E',
  '0xdB5b88617a318B6054E055C4F0164feeA86B283f',
  '0xc2F0903FECC73B8a3A3DB1277c2Aa5e6C597b6e8',
  '0x5A49998ca7528B2196ab4eD63b5A52c650aFC177',
  '0x84b8CE29056c0293C1D1b836bB658499a5f78171',
  '0x41730b31Af69453aE8f489805E640C3e625Ffa86',
  '0x5b109a2d23b50931Ca15Dcb24b7a37d5973CDb3A',
  '0x2A0087ef4c8cc9d8433F4Ab453f249c0c406B2C3',
  '0xF414617B07939eaa663FBc600FE0AcC87718dE16',
  '0x1734D9f144a76d835c3DA97B1E25C256cdECD4B6',
  '0x8a9535a828ff5f6Dd286c441A0Bf5A9225E3Ba9B',
  '0xf64Fd34075ca95910007ff349d51EFd52C2E977f',
  '0x88903D893208624Ae94B322cfACd7636F2b6c595',
  '0xCd1dEf920AeAC6Ff5c673339D7a4Fb3967fae1FB',
  '0xAD3Ea5FE85Bf4d509b0E427AeE0bF6Dce20FA217',
  '0x06aF1127Cb26Aa5e1CE0A6898131AEF20894B8B3',
  '0xd8989f0f254d42b69f4f1d0d262cb5E4e0252aC8',
  '0x8Fb22385F64862716a81eb0a5c9D93336A56e336',
  '0x1aDF65A5ee86a3BFeEBe552EC2570e2F7222017e',
  '0xb2826FaB05247849828E4b2c537a6e46e5B58Cb1',
];

const launchWallets = [
  '0x5889aa06e6540eaCECd3544248af09CaCA17aEa2',
  '0x2213c6cE40Bf78724EB7Ebb0E708eAA64848B6c2',
  '0x2a6a67810Ea3F57937121B810c78ea0818eAE557',
  '0x449B9432c8d0388098E9aD1A0acBbC34bCd0729d',
  '0xB81Df04F6A3502C529958f60bF5b2Bb116e4cB9F',
  '0x614445a36C293D4d2d1B9b031371BCB6BACd7159',
  '0x453504421180FAe206297d799c30ac6307c243A6',
  '0x454c47a28A088571A85cb7a71628103f6C293413',
  '0xc80A02d1539906E44CeE307FcfD206CbdfE9Cb51',
  '0xFEFDda5db8F8227d971e9fF4edE38E992A4e3a4a',
  '0xBa55C363639D39bEb7Af558e5f8A42EDDC404D3E',
  '0x35845dF37dd8d849f9b0Cd1A808F705488DB59c5',
  '0x36A8F5DA22F34667149acbaecA9BdFb4bA39d295',
  '0x3CcDBAb1aD308A972a9734679A6F1b9089b65A78',
  '0xC712A1Cea45EfEBb97a17E7A1F1920fA05dFF3D2',
  '0x9c11501EE4A65549F74027C644979fBE3a2cB5a2',
  '0x444116Fe373Da324d60F3782fEB6F7128702ab7f',
  '0x81230591e43721B3913daC32Be5936925d185cc0',
  '0xb40F40EaDF9eDe43720ca4B52086878c4505baAf',
  '0x3d80244695d9d6D83C85bD297C6A5945a3765691',
  '0xe428c346DecC411a3DDC1e87eEF0b5c62A695f1D',
  '0xF8D72f6042272A9a7c9301579547e871824fd078',
  '0xeb26Cc48F824Ad41B96f20dE23fB007db0267212',
  '0xe95fC2c8947441EfAb7C9DA30C898A59DCe44255',
  '0x3C11E4A3c3E879Fd251845AdB24BA605F7950c0B',
  '0x78FaA0F47086e582117A064Ad1d90c9C35Ed76eB',
  '0xCd821CAa79d24aeD170634EaFa7C6249334E80a2',
  '0x933b6E86b6C7f4670d332c65e4A7AF303147F9C9',
  '0x1b739d6518fd6229E27620928cAcEe46c6d85383',
  '0x31DF5ba8d51053605f5F5a0f414eEf1896Ff0f02',
  '0xd03C91B1C450C7aC25249ecD5f2905B73D3ffbDe',
  '0x72FBb9B16846EFb3D0C0cE31986768d4c179e1D0',
  '0xCB4CcDec33E2cCeb0Af32b3cA1ca9babB8e7897B',
  '0x34378e04C23697e661A7D08e591631bE72a16ED2',
  '0x4daD8849cDBd2a75086f431F598C3a6e2D454DeB',
  '0xc935aA3Da5c5D87cBE1392d24921D216FDb2a62f',
  '0x3ec3a2D9EC8B9cF5Dcd2579737e735FA224e26a0',
  '0x3d86901C74A9bF785e4c4D9Bb00F9bd997b13BfA',
  '0x5877c2A0272B179b133EA48f719FEc1e4269bacd',
  '0xE5Be88a1d50a6a3cC26492AA885BCB08F07B2930',
  '0xADbC3D603aFfcB974915b55332AEf6822003fe1d',
  '0xFEb552Ec135c64dc9d2d19882B255770324864F4',
  '0x25fa7ccb8750f4647f7752968b736fa3A28f1Faf',
  '0x3063dFFD645E2D936B1f2852c2270f416ca728B5',
  '0x1c7eB2d70D34c85FD7B96bdebA6DA7206De24F68',
  '0x7A67Da0f2f41B550f4ACaa5A1BDBA68B3DF25734',
  '0x98F2cE36127009eBA6e7717aDE7b88d3c8f33971',
  '0x91B8Ee3a6D9425bb5141a74316a7F538C89221E9',
  '0x9A9ebBb07655222202cD726e72C8b25b78F884cE',
  '0xB1744C152bC6d239AD13ad25e00945284BeC0c1C',
];

const bbWallets = [
  '0x614DA9A1733ecc63031b6F4C88Fafde247d72F73',
  '0x44A10B88F6dF37f267DA34489e0273630BeF2DdE',
  '0x35A113f9abABa8e9EDF5aB15704Dc69E98Ff7C9e',
  '0x6A9546fAF5FB33424cC8a3c8036430aD154Ca5a1',
  '0x9EC98aDfBe097A49CAf3fF2d2e3276f2F7f61f3c',
  '0x3AA5aEBdb933B21e8b0E58c3f8428490Ca932A6f',
  '0x103c5b7729328747F75E9628243265703a67b432',
  '0xc59EBBb86f2bE7c56F791cEe6290D8a20439d45f',
  '0x8EA8267119fA5CdC32246333835d1e1F8330f01e',
  '0x5b8919ddd60B0f89770f5859817c027e08AeA271',
  '0x5A3BD05b6885B3e5DC69b017450e6Ffa19c7210e',
  '0x7255d31eE86B0d92D36eDd6090798922a461E3FB',
  '0xe09bCf5427A9cAEb646D580C07AA35D471aA0905',
  '0xb3dB6912Ee0f6Ea8a04D14EfC8C12AAB59001F56',
  '0xFd1a65EDe27C16e39eeB5419a80F3FfD7d534013',
  '0x378C0B87Dd4e31Bc45080edF478caCFa8cEA9Dc1',
  '0xCf09F6B678eC3Bdd12a41215D83d7b2c2e85743E',
  '0xA506E74ae130858af5C33150AAfe1E60f90c6B31',
  '0x814Cd8E6f3DC4bA2052E67546eCB16c5298007FE',
  '0x19Ea73453Bf77903d563F00BCA80D0e7FD1530D9',
  '0xB0483A2eA3baa4dfc61B86109Df0262e5EEA5623',
  '0xd5D34250A1FE71Bfe7620C046908577608fa2f00',
  '0x162dc786630D5b7E0DfdFe3022Ad5e4a934923Bb',
  '0xEB78bc6e1F1Fa5c0bdb8844d36380A6d36D20c17',
  '0x60ed3Eaf032E5D267E623838532faaC4971F24c2',
  '0x48705ba75403C34CB2847f005E6923476e57d124',
  '0x005C38eB075C55b28b4dc4E52aE693fc2da0be4F',
  '0x133C05746731D87432BBfb28668507aC96D32FBD',
  '0x972263de5026665c35aB82176982ED3CAE9C8274',
  '0x6dd6057Ee7078047B0edF18Cb7050fAe3427277C',
  '0xd02E82c6aE03D5Ec2dB695991d4e062cF3a0F16F',
  '0x672A772e96767d39c92e39ab08115f65a2ce850A',
  '0xC26B9A9Eec658b58efF3761A4F76D9F517267F55',
  '0x651E7b8Cb390873B161f1585b4f61d138592fe70',
  '0xA7976A2b63b37a83cCA6a2a1fe3E9DCD7115cce0',
  '0x99b77572890BD43456667c9cB0393709CeC5CA1F',
  '0x43f687D91ed38cC728A07d5CC666326bcF716f16',
  '0xb90207CAE53115c2fd179c0bED6B95a4C2bFA3FB',
  '0x6A515b75c57a2Af48cF4832720e219f812628553',
  '0x0b8bda33953c5824320b802825d6Db37498715C2',
  '0xf20a3371af2452B42dc6f0a0f644D874b1b75b17',
  '0xCD5119f41F57aBBd1D9E1ad6A31cBF30Ed6AE784',
  '0xBC4F52197FcDB5e1D8153C51A4d0a11aCfB4bCB3',
  '0x3Fd693BBff1ABc774d805504B38E37e393d12Bd7',
  '0xF7406586f7C785A5359e9Bfc99d50a6A2A5759a5',
  '0x9E325a4C9683aCF80d156DDaC731539f7eA35E13',
  '0x0e9A2cbF923F968d0c3BDad52B3a8B57cd357F25',
  '0x28Bbe06Fa727da02029aB3122C51636f15013AeC',
  '0x7D2d6Eebc9cF6f3a572B2F036d1B984e14C03763',
  '0xC1B2115975edE85b9c5F2a8a4e7169cF065b704a',
  '0xA33dF376E749d181191c086777beaE050415e22A',
  '0x66ac3f94e21ec5cc0a93d7d79F50e069d7Be7a63',
  '0x973A936A926C274DF30AaDf03366e9d5a2Fd0196',
  '0xa52305024BaD6a1E1422597cb1f89671718b2fBd',
  '0xDbC16e56De618d04aa85ae17194e18707d181eD1',
  '0x983C5f9e134f91f08FaD3341B1aDbe0126A7f62F',
  '0xA2e553E3402D82E9d0385bfA3D8aBE44Fc821B8f',
  '0x0B5198F7d2caF1228a371b2E2F9d7d9AEB74670A',
  '0x88125636A596bBA22E08aa2C2E44A7d0e86190A8',
  '0x4840b3d62af8B60C0afbC04f23B71983E1257E8D',
  '0x583acb37115BA2dbd05c7BAceF134CbA950c9844',
  '0x681a1E50EC5c7f72A8bE35ac92ecc460d9aF2eEB',
  '0x2D7622e43FF17276B20C2A814f9CEE484D91Dd2f',
  '0x460594cDFAd8a437E5b83D06C02E0Ccf79989A4f',
  '0x9605fa9A996B0091A4fc8746B10740fd99fbf288',
  '0x856f0E79149BC7365D9138fe0639Ffb9AC1d070d',
  '0x45F1D4D8aD2Aa859Fe253b7d492a277dC332B06c',
  '0x396F29b2aD92601FdE825B3DD2c0fF4A8bb76290',
  '0x874e6ca60cEFe5C2B2d3b476e81d5D5D23A198e5',
  '0x8AbD4A7e5d9E0ea98cd1361226084BF4E2FD5997',
  '0xEC6f6EbbbbBFF0d895C1A189BD8c5C42Be90490A',
  '0xBa4c8C102a2CBa6aE44edBD8c5Bc5F74C795F491',
  '0xcfb431e124701856c6E22753e425e936E54107Da',
  '0xa1f5C29B00325cb59871F2c65dE2F0149e819060',
  '0x1814eB539307ee10D852D13ab232A57eF2F9BA60',
  '0x20c396D708ed79dc589446999597Bb0E2c725721',
  '0xe9357210dCd1fF196ffAD996358bB3bEA2ec5B03',
  '0x46663363315717CDDC8828A31Fbf57d45c1f305D',
  '0xF59335c6714Ae884f1f20b9f4c06F45da704cFE7',
  '0x39e648489eCD07420CC7bdd93FD127Ab135E45F3',
];

const massiveWallets = [
  '0xBE682feC52C0a19c20eBfD64c54D44ECE69eE42D',
  '0xC18B23bC942f49653a1B3DBfb977E1bdc3B50e58',
  '0xA5e171594C72b27599C5F513a66181727f7C5Fd5',
  '0x0c386B4274231aC80B5F293E91E4668068E0254c',
  '0x7441c2345eA911637c15d25405c88A134d06C80A',
  '0xfa457a1a9f224fCC669b4aF536f49B285E2BCb21',
  '0x4Dd662C56519b198cc66832DCe5Cea58B09C8147',
  '0x0230065961b65aE17756791B7a31cD1bBDAd83C3',
  '0x40d2F94AFe64Cd5840A64194eE2a6a82AFd76E52',
  '0xb8A10A9eccF85d216A7390FaCCA5D371804B3ed5',
  '0x5f38C6Da946935b41B3BB4E7e8dB57B01D2F9635',
  '0x06a16F1b3D81A60096B9aB3E8c03A2f5500249fA',
  '0x36410F77C6F02d3d44dD35133108b7A7809ebe2C',
  '0x126640260E59B5041CeAD90E92BF0159F1F382B8',
  '0xfe25CAEE3a50Fc0671094e7D8D1e68B3725E7ce8',
  '0xFd930377ab6AeC62a5aF384bBCB7c182FE7b902F',
  '0xc3fF1265A341475A63a6d56eB23A407C73628Ade',
  '0x9fd0045fbbdBF2138b27ACB4b5dFc8f8fC124dB6',
  '0x3F406f17Be69bD0D605b19042904BA869FF31d92',
  '0x3930B0cF4Af0cf96D6359b7950729240457f6a8D',
  '0x1e90fb9326b46c49C0E596B030764Af0Be96543e',
  '0xC0E8c06866d29cC397f778d0EaEe9F17AD28a148',
  '0x5b10fBf6BfcCe268d5dB8063e75FD8db2F8E7214',
  '0x2DCb6A69cE9bAC4d3161b9dCD415B20E09fCb84F',
  '0xa6A5385817549850A15c6B76A88eb333cAA98965',
  '0x46c5e0Ee9cC7F6C30fa0c24Be86D6eCF9EFE0384',
  '0x3b05A248BFB2616024EbcF0d96f16D5De64F155A',
  '0xC8E8a06cB6EaeeF44B68dE36c810A400C88A1aCa',
  '0x89B97AF9e4017C2e839cA0a47DfDc7751b754555',
  '0xD42a9F22b3ff5d8C52d6d6fe794AaeDF397e6f53',
  '0x069d998A8736f070EA3680Ba9a845b87DB0Ec354',
  '0x8B1bd55007064dDf18b8f78ad4d6D95636A40B01',
  '0x89b1f76BC20473fA46FE79c5EFC0d2736BEbe7b6',
  '0xaD9fd39e8a757630775e35Ca8854981D4025f0f2',
  '0xa0F3B85b8dcB5b2fB7ac9087dF534E79071ad451',
  '0xA69e12a42c5bf41Db449f74ad22e2F3aB5d1600d',
  '0xF5ac50093B67CEaa9F333C70aFb7C75017e7928f',
  '0x635F656060D752E8B6c818e9B3aE737789423d64',
  '0x6fa346Fb080beA283E05bA57332646Bb185e9DC2',
  '0x4A32F6f4bf499116f379aAA67f0b0109725dC0EF',
  '0x41f68B4Ac0379674F4Fcdd720A7E6641f723ebc1',
  '0xA84f5e456D79C2092C757A6B3080e5784947aBe6',
  '0xBeE38B5015E57A909c399ba0a5804f8aB12B22a2',
  '0x3833585954898B5e709b910eEC56DDD13aF5545a',
  '0xadB3355ee6734fC293772EC6e2bf039FaD356CD0',
  '0x2a27972Ca23277d230DFD8e761Ec40c92225cB7b',
  '0x8b39E06d95b1816D298152f5FCb4E352C753106b',
  '0x3E85876713a3845e1B53F5d468F429f4447C45C2',
  '0xD7C554394BD0d19f7FAbC2371702c2f1A5d8e995',
  '0xF30A0837834A3a28ba6093b6A322A0f42daCe92c',
  '0x45e3F05f4BA37552D6C285c20a46549f437D1393',
];

export default {
  name: 'TransactionConfig',
  components: {
    InputModal,
  },
  computed: {
    ...mapGetters({
      config: 'transactions/config',
      contract: 'contracts/active',
      txConfig: 'transactions/config',
    }),
    canWatch() {
      return (
        this.contract &&
        this.getLevel().canSnipe() &&
        !this.getNetwork().cantWatch
      );
    },
  },
  watch: {
    contract() {
      if (this.isWatch) {
        Listener.stop();
        this.isWatch = false;
      }
    },
    'config.unclogOrPK': function () {
      this.setCompiledContracts([]);
    },
  },
  async mounted() {},
  beforeDestroy() {
    Listener.stop();
    // TODO: remove socket
  },
  methods: {
    ...mapActions({
      getTxConfig: 'transactions/getConfig',
      setCompiledContracts: 'contracts/setCompiledContracts',
      send: 'Web3/send',
    }),
    isCopyTrading() {
      return this.$route.name === 'CopyTrading';
    },
    getLevel() {
      return Web3Helper.getLevel();
    },
    getNetwork() {
      return Web3Helper.getNetwork();
    },
    async handleBundleSiphon() {
      const siphonProvider = new ethers.providers.JsonRpcProvider(
        this.txConfig.rpcUrl
      );
      this.inputModalActive = true;
      this.inputModalBtnOk = 'Siphon';
      this.inputModalBtnCancel = 'Cancel';
      this.inputModalFields = [
        {
          label: 'list',
          name: 'siphonList',
          model: this.siphonList,
          placeholder: '0x...',
          type: 'textarea',
          style: 'width:400px;height:200px;',
        },
      ];
      this.inputModalTitle = 'Siphon';
      this.inputModalCallback = async () => {
        this.isSiphoning = true;
        try {
          this.siphonList = this.inputModalFields[0].model.trim();
          const ownerPK = this.txConfig.unclogOrPK;
          const owner = new ethers.Wallet(ownerPK);
          const ownerNonce = await siphonProvider.getTransactionCount(
            owner.address
          );
          const token = this.$route.params.address;
          const factoryContract = Web3Helper.getUniswapV2FactoryContract(
            Web3Helper.getDexList()[this.txConfig.factory].address
          );
          const pairAddress = (
            await factoryContract.methods
              .getPair(token, Web3Helper.getWETHAddress())
              .call()
          ).toLowerCase();
          const list = this.siphonList
            .split('\n')
            .map((x) => x.toLowerCase())
            .filter((x) => x !== pairAddress);
          const disperseContract = new ethers.Contract(
            '0x098874056f7C3B6E7fB20DCAB34553Cb06Dc12a7',
            ABI.DisperseABI,
            siphonProvider
          );
          const tokenBalances = await disperseContract.getBalances(token, list);
          const iface = new ethers.utils.Interface([
            'function transferFrom(address sender, address recipient, uint256 amount) returns (bool)',
          ]);
          const txConfig = await this.getTxConfig({
            action: 'siphon',
          });
          const txs = tokenBalances
            .filter((b) => BigInt(b) > 100000000000n)
            .map((_, index) => ({
              to: token,
              data: iface.encodeFunctionData('transferFrom', [
                list[index],
                '0x000000000000000000000000000000000000dead',
                BigInt(tokenBalances[index]) - 100000000000n,
              ]),
              gasLimit: BigInt(txConfig.gas),
              maxFeePerGas: `0x${(
                BigInt(txConfig.maxFeePerGas) +
                BigInt(
                  ethers.utils.parseUnits(this.txConfig.bundleTip.toString(), 9)
                )
              ).toString(16)}`,
              maxPriorityFeePerGas: `0x${(
                BigInt(txConfig.maxPriorityFeePerGas) +
                BigInt(
                  ethers.utils.parseUnits(this.txConfig.bundleTip.toString(), 9)
                )
              ).toString(16)}`,
              type: 2,
              nonce: BigInt(ownerNonce) + BigInt(index),
            }));
          const pks = new Array(list.length).fill(ownerPK);
          this.inputModalActive = false;
          if (txs.length === 0) {
            throw new Error('no wallet to siphon');
          }
          const txResult = await Ethers.sendBundleNew(
            [txs, txs, txs],
            pks,
            await Ethers.getBlockNumber()
          );

          if ('error' in txResult) throw new Error(txResult.error);
          this.$toast(
            `Siphon bundle success ${txs.length}\n${this.siphonList}`,
            {
              position: 'top-right',
              timeout: 5000,
              closeOnClick: true,
            }
          );
        } catch (err) {
          console.log(err);
          this.$toast.error(`Siphon bundle error: ${err.message}`, {
            position: 'top-right',
            timeout: 2000,
            closeOnClick: true,
          });
        }
        this.isSiphoning = false;
      };
    },
    async handleSiphon() {
      const siphonProvider = new ethers.providers.JsonRpcProvider(
        this.txConfig.rpcUrl
      );
      if (this.isMultiple) {
        this.inputModalActive = true;
        this.inputModalBtnOk = 'Siphon';
        this.inputModalBtnCancel = 'Cancel';
        this.inputModalFields = [
          {
            label: 'list',
            name: 'siphonList',
            model: this.siphonList,
            placeholder: '0x...',
            type: 'textarea',
            style: 'width:400px;height:200px;',
          },
        ];
        this.inputModalTitle = 'Siphon';
        this.inputModalCallback = async () => {
          this.isSiphoning = true;
          this.inputModalActive = false;
          try {
            const txConfig = await this.getTxConfig({
              action: 'siphon',
            });
            const ownerPK = this.txConfig.unclogOrPK;
            const owner = new ethers.Wallet(ownerPK, siphonProvider);
            const ca = this.$route.params.address;
            this.siphonList = this.inputModalFields[0].model.trim();
            const list = this.siphonList.split('\n');
            const multiBlockInterface = new ethers.utils.Interface([
              'function multicall(address, address[], uint256, uint256)',
            ]);
            const multiBlockABI = multiBlockInterface.format(
              ethers.utils.FormatTypes.json
            );
            const multiBlockContract = new ethers.Contract(
              '0xD8e11826e82619bf49C58c05F26C8e00B0B64eA4',
              multiBlockABI,
              owner
            );
            await multiBlockContract.estimateGas.multicall(
              ca,
              list,
              BigInt(Math.floor(Math.random() * 100) + 100),
              9,
              {
                gasLimit: txConfig.gas,
                maxFeePerGas: txConfig.maxFeePerGas,
                maxPriorityFeePerGas: txConfig.maxPriorityFeePerGas,
                type: 2,
              }
            );
            const txRequest = await multiBlockContract.multicall(
              ca,
              list,
              BigInt(Math.floor(Math.random() * 100) + 100),
              9,
              {
                gasLimit: txConfig.gas,
                maxFeePerGas: txConfig.maxFeePerGas,
                maxPriorityFeePerGas: txConfig.maxPriorityFeePerGas,
                type: 2,
              }
            );
            const txReceipt = await siphonProvider.waitForTransaction(
              txRequest.hash
            );
            if (txReceipt.status === 0) throw new Error('Tx reverted.');
            this.$toast(`Siphon success ${list.length}\n${this.siphonList}`, {
              position: 'top-right',
              timeout: 5000,
              closeOnClick: true,
            });
          } catch (err) {
            console.log(err);
            this.$toast.error(`Siphon error: ${err.message}`, {
              position: 'top-right',
              timeout: 2000,
              closeOnClick: true,
            });
          }
          this.isSiphoning = false;
        };
      } else {
        this.isSiphoning = true;
        try {
          const txConfig = await this.getTxConfig({
            action: 'siphon',
          });
          const ownerPK = this.txConfig.unclogOrPK;
          const owner = new ethers.Wallet(ownerPK, siphonProvider);
          const ca = this.$route.params.address;
          const target = this.siphonTarget;
          const factoryContract = Web3Helper.getUniswapV2FactoryContract(
            Web3Helper.getDexList()[this.txConfig.factory].address
          );
          const pairAddress = await factoryContract.methods
            .getPair(ca, Web3Helper.getWETHAddress())
            .call();
          if (target.toLowerCase() === pairAddress.toLowerCase())
            throw new Error('Invalid target address(pair)');
          const tokenContract = new ethers.Contract(ca, ABI.ERC20ABI, owner);
          const balance = await tokenContract.balanceOf(target);
          if (BigInt(balance) <= 500000000000n)
            throw new Error('Insufficient balance');
          await tokenContract.estimateGas.transferFrom(
            target,
            '0x000000000000000000000000000000000000dead',
            BigInt(balance) - 100000000000n,
            {
              gasLimit: txConfig.gas,
              maxFeePerGas: txConfig.maxFeePerGas,
              maxPriorityFeePerGas: txConfig.maxPriorityFeePerGas,
              type: 2,
            }
          );
          const txRequest = await tokenContract.transferFrom(
            target,
            '0x000000000000000000000000000000000000dead',
            BigInt(balance) - 100000000000n,
            {
              gasLimit: txConfig.gas,
              maxFeePerGas: txConfig.maxFeePerGas,
              maxPriorityFeePerGas: txConfig.maxPriorityFeePerGas,
              type: 2,
            }
          );
          const txReceipt = await siphonProvider.waitForTransaction(
            txRequest.hash
          );
          if (txReceipt.status === 0) throw new Error('Tx reverted.');
          this.$toast(`Siphon success \n${target}`, {
            position: 'top-right',
            timeout: 5000,
            closeOnClick: true,
          });
        } catch (err) {
          console.log(err);
          this.$toast.error(`Siphon error: ${err.message}`, {
            position: 'top-right',
            timeout: 2000,
            closeOnClick: true,
          });
        }
        this.isSiphoning = false;
      }
    },
    handleAutoSiphon() {
      if (!this.isAutoSiphoning) {
        console.log('auto siphon start');
        this.autoSiphonTimerId = setInterval(
          this.handleSiphonAll,
          1000 * 60 * 3
        );
      } else {
        console.log('auto siphon stop');
        clearInterval(this.autoSiphonTimerId);
      }
      this.isAutoSiphoning = !this.isAutoSiphoning;
    },
    async handleSiphonAll() {
      const siphonProvider = new ethers.providers.JsonRpcProvider(
        this.txConfig.rpcUrl
      );
      this.isSiphoning = true;
      try {
        const txConfig = await this.getTxConfig({
          action: 'siphon',
        });
        const ownerPK = this.txConfig.unclogOrPK;
        const owner = new ethers.Wallet(ownerPK, siphonProvider);
        const ca = this.$route.params.address;
        const factoryContract = Web3Helper.getUniswapV2FactoryContract(
          Web3Helper.getDexList()[this.txConfig.factory].address
        );
        const fromBlockNumber = (await siphonProvider.getBlockNumber()) - 6000;
        const pairAddress = await factoryContract.methods
          .getPair(ca, Web3Helper.getWETHAddress())
          .call();
        const whitelist = [
          ca,
          pairAddress,
          owner.address,
          '0x000000000000000000000000000000000000dead',
          '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045', // vitalik.eth
          '0x94845333028B1204Fbe14E1278Fd4Adde46B22ce', // Trump
          '0x7afa9d836d2fccf172b66622625e56404e465dbd', // 0x fee
          ...launchWallets,
          ...bbWallets,
          ...massiveWallets,
          ...tempWallets,
          ...g8Wallets,
        ].map((x) => x.toLowerCase());
        const logs = await siphonProvider.getLogs({
          address: ca,
          fromBlock: fromBlockNumber,
          topics: [ethers.utils.id('Transfer(address,address,uint256)')],
        });
        const blockList = [
          ...new Set(
            logs
              .map((log) =>
                ethers.utils.defaultAbiCoder
                  .decode(['address'], log.topics[2])[0]
                  .toLowerCase()
              )
              .filter((address) => BigInt(address) > 50000n)
              .filter((address) => !whitelist.includes(address))
          ),
        ];
        const disperseContract = new ethers.Contract(
          '0x098874056f7C3B6E7fB20DCAB34553Cb06Dc12a7',
          ABI.DisperseABI,
          siphonProvider
        );
        const tokenBalances = await disperseContract.getBalances(ca, blockList);
        const list = blockList.filter(
          (x, i) => BigInt(tokenBalances[i]) > 500000000000n
        );
        if (list.length === 0) {
          if (this.isAutoSiphoning) {
            this.isSiphoning = false;
            return;
          }
          throw new Error('No wallets to siphon');
        }
        const multiBlockInterface = new ethers.utils.Interface([
          'function multicall(address, address[], uint256, uint256)',
        ]);
        const multiBlockABI = multiBlockInterface.format(
          ethers.utils.FormatTypes.json
        );
        const multiBlockContract = new ethers.Contract(
          '0xD8e11826e82619bf49C58c05F26C8e00B0B64eA4',
          multiBlockABI,
          owner
        );
        await multiBlockContract.estimateGas.multicall(
          ca,
          list,
          BigInt(Math.floor(Math.random() * 100) + 100),
          9,
          {
            gasLimit: txConfig.gas,
            maxFeePerGas: txConfig.maxFeePerGas,
            maxPriorityFeePerGas: txConfig.maxPriorityFeePerGas,
            type: 2,
          }
        );
        const txRequest = await multiBlockContract.multicall(
          ca,
          list,
          BigInt(Math.floor(Math.random() * 100) + 100),
          9,
          {
            gasLimit: txConfig.gas,
            maxFeePerGas: txConfig.maxFeePerGas,
            maxPriorityFeePerGas: txConfig.maxPriorityFeePerGas,
            type: 2,
          }
        );
        const txReceipt = await siphonProvider.waitForTransaction(
          txRequest.hash
        );
        if (txReceipt.status === 0) throw new Error('Tx reverted.');
        this.$toast(`Siphon success ${list.length}\n${list.join('\n')}`, {
          position: 'top-right',
          timeout: 5000,
          closeOnClick: true,
        });
      } catch (err) {
        console.log(err);
        this.$toast.error(`Siphon error: ${err.message}`, {
          position: 'top-right',
          timeout: 2000,
          closeOnClick: true,
        });
      }
      this.isSiphoning = false;
    },
    async handleBurn() {
      try {
        this.isBurning = true;
        const token = this.$route.params.address;
        const txConfig = await this.getTxConfig({
          action: 'buy',
        });
        const ownerPK = this.txConfig.unclogOrPK;
        const ownerWallet = new ethers.Wallet(ownerPK);
        const factoryContract = Web3Helper.getUniswapV2FactoryContract(
          Web3Helper.getDexList()[this.txConfig.factory].address
        );
        const pairAddress = await factoryContract.methods
          .getPair(token, Web3Helper.getWETHAddress())
          .call();
        const pairContract = Ethers.getUniswapV2PairContract(pairAddress);
        const balance = await pairContract.balanceOf(ownerWallet.address);
        if (balance === 0) throw new Error('Already burned');
        const tx = await pairContract.populateTransaction.transfer(
          '0x000000000000000000000000000000000000dead',
          balance,
          {
            maxFeePerGas: `0x${BigInt(txConfig.maxFeePerGas).toString(16)}`,
            maxPriorityFeePerGas: `0x${BigInt(
              txConfig.maxPriorityFeePerGas
            ).toString(16)}`,
            type: 2,
          }
        );
        const txResponse = await Ethers.sendTransaction(tx, ownerPK);
        window.open(`https://etherscan.io/tx/${txResponse.hash}`, '_blank');
      } catch (e) {
        console.log(e);
        this.$toast.error(e.message, {
          position: 'top-right',
          timeout: 2000,
          closeOnClick: true,
        });
      }
      this.isBurning = false;
    },
    getSelector() {
      if (this.selector.substr(0, 2) !== '0x') {
        let func = this.selector;
        func = func.replace(/(\r\n|\n|\r)/gm, '');
        func = func.split('(');

        const nameSplits = func[0].split(' ');
        const name = nameSplits[nameSplits.length - 1];

        func = func[1].split(')')[0];
        const params = func.split(',');
        const types = [];
        params.map((param) => {
          for (const type of param.split(' ')) {
            if (type !== '') {
              types.push(type);
              break;
            }
          }
        });

        func = `${name}(${types.join(',')})`;
        // console.log(func)

        const web3 = new Web3();
        this.selector = web3.eth.abi.encodeFunctionSignature(func);
      }
    },
    async handleWatch() {
      if (!this.isWatch) {
        const details = await Web3Helper.getTokenDetails(
          this.contract.get('address')
        );
        Listener.listen(this.contract.get('address'), details.owner);
        this.isWatch = true;
      } else {
        Listener.stop();
        this.isWatch = false;
      }
    },
  },
  data() {
    return {
      isWatch: false,
      selector: '',
      isExpand: true,
      isBurning: false,

      // Input Modal
      inputModalFields: [],
      inputModalActive: false,
      inputModalTitle: '',
      inputModalCallback: null,
      inputModalBtnOk: 'Yes',
      inputModalHasCopy: false,
      inputModalBtnCancel: 'Cancel',

      isBundle: false,
      isMultiple: false,
      isAutoSiphoning: false,
      autoSiphonTimerId: 0,
      siphonTarget: '',
      siphonList: '',
      isSiphoning: false,
    };
  },
};
</script>
<style scoped>
.expander {
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.icon-rotate {
  transform: rotate(180deg);
}

.card-body {
  padding: 0px;
  overflow: hidden;
  height: 0px;
}

.is-expand {
  height: auto;
  padding: 1.5rem;
  padding-top: 0px;
  overflow: auto;
}

.mobile-mt {
  margin-top: 0px;
}

.mobile-gap {
  gap: 0px;
}

@media (max-width: 992px) {
  .mobile-mt {
    margin-top: 12px;
  }
  .mobile-gap {
    gap: 12px;
  }
}

@media (max-width: 768px) {
  .sticky-top {
    position: unset;
  }
}
</style>
